import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Box, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Чистка BioClean
			</title>
			<meta name={"description"} content={"Делаем каждое пространство безупречным"} />
			<meta property={"og:title"} content={"Чистка BioClean"} />
			<meta property={"og:description"} content={"Делаем каждое пространство безупречным"} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1584819762556-68601d7f3a86?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link1" />
		</Components.Header>
		<Section
			color="--light"
			padding="100px 0"
			sm-padding="40px 0"
			position="relative"
			background="linear-gradient(0deg,rgba(25, 30, 34, 0.8) 0%,rgba(25, 30, 34, 0.8) 100%),--color-darkL2 url(https://images.unsplash.com/photo-1592296429945-93008c7e5a59?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000) bottom"
			quarkly-title="Form-1"
		>
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="50%" md-width="100%">
					<Box>
						<Box
							sm-padding="64px 0 0 0"
							margin="32px 0 0 0"
							max-width="360px"
							position="relative"
							padding="0 0 0 64px"
						>
							<Icon
								size="48px"
								top="0"
								left="0"
								category="md"
								icon={MdLocationOn}
								position="absolute"
							/>
							<Text as="h4" margin="6px 0" font="--base">
								Посетите нас
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								Kuyshi Dina St 5, Astana 010000, Kazakhstan
							</Text>
						</Box>
						<Box
							position="relative"
							padding="0 0 0 64px"
							sm-padding="64px 0 0 0"
							margin="64px 0 0 0"
							max-width="360px"
						>
							<Icon
								top="0"
								left="0"
								category="md"
								icon={MdEmail}
								position="absolute"
								size="48px"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Напишите нам
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								<Link href="mailto:info@nexolight.com" text-decoration="none" hover-text-decoration="underline" color="--light">
									info@nexolight.com
								</Link>
							</Text>
						</Box>
						<Box padding="0 0 0 64px" margin="64px 0 0 0" max-width="360px" position="relative">
							<Icon
								left="0"
								category="md"
								icon={MdPhone}
								position="absolute"
								size="48px"
								top="0"
							/>
							<Text font="--base" as="h4" margin="6px 0">
								Звоните
							</Text>
							<Text as="p" margin="6px 0" font="--headline3">
								+77766885131
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					align-items="stretch"
					flex-direction="column"
					justify-content="center"
					text-align="right"
				>
					<Text margin="0px 0px 0px 0px" font="--headline2">
						Готовы ощутить безупречную чистоту?
					</Text>
					<Text margin="3rem 0px 0px 0px" font="--base">
						Не соглашайтесь на обычное. Выберите Чистка BioClean для услуг, выходящих за рамки простой уборки. Свяжитесь с нами сегодня, чтобы запланировать свой первый сеанс, и сами увидите разницу. Позвольте нам помочь вам сохранить пространство не просто чистым, но и блестящим.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"666ac2cae8900d0022f35234"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});